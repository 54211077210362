export function authHeader(params) {
    let header = {'Content-Type': 'application/json'};
    try {
        let token = '';
        if( params != null && Object.prototype.hasOwnProperty.call(params, 'token') ) {
            token = params.token;
        } else {
            let _user = localStorage.getItem('user');
            token = _user != null ? JSON.parse(_user).token : '';
        }
        if( token != '' ) header['Authorization'] = 'Bearer ' + token;
    } catch (error) {
        // console.log('authHeader = ', error);
    }
    return header;
}
