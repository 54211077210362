import { useLayoutStore } from "./pinia/layout";
import { useAuthFakeStore } from "./pinia/authFake";
import { useAuthApiStore } from "./pinia/authApi";
import { useMyProStore } from "./pinia/myPro";

import { useAuthStore } from "./pinia/auth";
import { useNotificationStore } from "./pinia/notification";
import { createPinia } from "pinia";

const pinia = createPinia();
export default pinia;

export { useLayoutStore, useAuthFakeStore, useAuthApiStore, useAuthStore, useNotificationStore, useMyProStore };
