import { useAuthStore, useAuthFakeStore } from "@/state/pinia";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: 'menuitems.child.console.translate.console', // 控制台
      // title: '控制台',
      authRequired: true
    },
    component: () => import("../views/dashboards/default")
  },
  {
    path: "/login/:key?",
    name: "login",
    component: () => import("../views/account/login"), // ['login', 'login-map']
    meta: {
      title: "admin.child.login.translate.login", // 登录
      // title: '登录',
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/account/register"),
    meta: {
      title: "admin.child.login.translate.register", // 注册
      // title: '注册',
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password"),
    meta: {
      title: "admin.child.login.translate.forgotPassword", // 找回密码
      // title: '找回密码',
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        // If the user is already logged in
        // if (store.getters["auth/loggedIn"]) {
        if (auth.loggedIn) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("../views/account/logout"),
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        const auth = useAuthStore();
        const authFake = useAuthFakeStore();

        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          auth.logOut();
        } else {
          authFake.logout();
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>{
          route.push("/login")
        });
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom } );
      }
    }
  },
  {
    path: "/short-url",
    name: "short-url",
    meta: {
      title: "menuitems.child.shortLink.translate.shortLink", // 短链接
      // title: '短链接',
      authRequired: true
    },
    component: () => import("../views/short-url/index")
  },
  {
    path: "/address-book/contacts",
    name: "contacts",
    meta: {
      title: "admin.child.addressBook.translate.addressBook", // 通讯录
      // title: '通讯录',
      authRequired: true
    },
    component: () => import("../views/address-book/contacts")
  },
  {
    path: "/message/sms-box/:sp_id?",
    name: "sms-box",
    meta: {
      title: "admin.child.myProduct.translate.send", // 短信
      // title: '短信',
      authRequired: true
    },
    component: () => import("../views/message/sms-box")
  },
  {
    path: "/message/overview/:sp_id?",
    name: "overview",
    meta: {
      title: "admin.child.myProduct.translate.overview", // 概况
      // title: '概况',
      authRequired: true
    },
    component: () => import("../views/message/overview")
  },
  {
    path: "/detection/international",
    name: "detection international",
    meta: {
      title: "menuitems.child.internationalTesting.translate.internationalTesting", // 国际检测
      // title: '国际检测',
      authRequired: true
    },
    component: () => import("../views/detection/international")
  },
  {
    path: "/message/template-list/:sp_id",
    name: "template-list",
    meta: {
      title: "admin.child.myProduct.translate.tplReporting", // 模板报备
      // title: '模板报备',
      authRequired: true
    },
    component: () => import("../views/message/template-list")
  },
  {
    path: "/message/task-list/:sp_id",
    name: "task-list",
    meta: {
      title: "admin.child.myProduct.translate.sendTasks", // 发送任务
      // title: '发送任务',
      authRequired: true
    },
    component: () => import("../views/message/task-list")
  },
  {
    path: "/message/msg-list/:sp_id",
    name: "msg-list",
    meta: {
      title: "admin.child.myProduct.translate.sendDetails", // 短信发送明细
      // title: '短信发送明细',
      authRequired: true
    },
    component: () => import("../views/message/msg-list")
  },
  {
    path: "/message/deliver-list/:sp_id",
    name: "deliver-list",
    meta: {
      title: "admin.child.myProduct.translate.replySMS", // 回复短信
      // title: '回复短信',
      authRequired: true
    },
    component: () => import("../views/message/deliver-list")
  },
  {
    path: "/message/developers/:sp_id",
    name: "developers",
    meta: {
      title: "admin.child.myProduct.translate.developer", // 开发者
      // title: '开发者',
      authRequired: true
    },
    component: () => import("../views/message/developers")
  },
  {
    path: "/charts/echart-list",
    name: "echart-list",
    meta: {
      title: "menuitems.child.statisticalReport.translate.statisticalReport", // 统计列表
      // title: '统计列表',
      authRequired: true
    },
    component: () => import("../views/charts/echart-list")
  },
  {
    path: "/finance/wallet",
    name: "wallet",
    meta: {
      title: "menuitems.child.financial.child.wallet.translate.walletDetails", // 钱包明细
      // title: '钱包明细',
      authRequired: true
    },
    component: () => import("../views//finance/wallet")
  },
  {
    path: "/finance/recharge",
    name: "recharge",
    meta: {
      title: "admin.child.financialManagement.child.rechargeDetails.translate.rechargeDetail", // 充值明细
      // title: '充值明细',
      authRequired: true
    },
    component: () => import("../views/finance/recharge")
  },
  {
    path: "/my-customer/customer",
    name: "myCustomer Customer",
    meta: { title: "admin.child.myProduct.translate.myClient", authRequired: true },
    component: () => import("../views/my-customer/customer")
  },
  {
    path: "/my-customer/customer-recharge",
    name: "Customer recharge",
    meta: { title: "menuitems.child.financial.child.recharge.translate.recharge", authRequired: true },
    component: () => import("../views/my-customer/customer-recharge")
  },
  {
    path: "/contacts/profile",
    name: "profile",
    meta: {
      title: "menuitems.child.accountSettings.translate.accountSettings", // 账号设置
      // title: '账号设置',
      authRequired: true
    },
    component: () => import("../views/contacts/contacts-profile")
  },
];
