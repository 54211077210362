  import { defineStore } from "pinia";

  export const useLayoutStore = defineStore("layout", {
    state: () => ({
      layoutType: 'vertical', // localStorage.getItem('layoutType') || "vertical", // horizontal vertical
      leftSidebarType: 'compact', // localStorage.getItem('leftSidebarType') || "dark", // "dark",
      layoutWidth: 'fluid', // localStorage.getItem('layoutWidth') || "fluid", //"fluid",
      topbar: 'dark', // localStorage.getItem('topbar') || "dark", // "dark",
      loader: false,
      mode: localStorage.getItem('mode') || "light", // "light"
    }),
    actions: {
      changeLayoutType(layoutType) {
        localStorage.setItem('layoutType', layoutType);
        this.layoutType = layoutType;
      },
      changeLayoutWidth(layoutWidth) {
        localStorage.setItem('layoutWidth', layoutWidth);
        this.layoutWidth = layoutWidth;
      },
      changeLeftSidebarType(leftSidebarType) {
        localStorage.setItem('leftSidebarType', leftSidebarType);
        this.leftSidebarType = leftSidebarType;
      },
      changeTopbar(topbar) {
        localStorage.setItem('topbar', topbar);
        this.topbar = topbar;
      },
      changeLoaderValue(loader) {
        this.loader = loader;
      },
      changeMode(mode) {
        localStorage.setItem('mode', mode);
        this.mode = mode
      }
    }
  });