import { defineStore } from "pinia";
import { apiService } from '../../helpers/authservice/api.service';
import router from '../../router/index'
import { useNotificationStore } from "./notification"

const _token = localStorage.getItem('user');
const _userInfo = localStorage.getItem('user-info');
const _websiteInfo = localStorage.getItem('website-info');

export const useAuthApiStore = defineStore("authApi", {
    state: () => ({
        user: _token != null ? JSON.parse(_token) : null,
        status: _token != null ? { loggeduser: true } : {},
        userInfo: _userInfo != null ? JSON.parse(_userInfo) : null,
        websiteInfo: _websiteInfo != null ? JSON.parse(_websiteInfo) : null,
    }),
    getters: {
        loggedIn() {
            return !!this.user
        }
    },
    actions: {
        login(account, password) {
            this.loginRequest(account);
            apiService.login(account, password).then((user) => {
                    if( user.errCode === 0 ) {
                        this.loginSuccess(user.data);
                    } else {
                        this.loginFailure()
                        const notificationStore = useNotificationStore();
                        notificationStore.error(user.errMsg);
                    }
                }, (error) => {
                    this.loginFailure()
                    const notificationStore = useNotificationStore();
                    notificationStore.error(error);
                }
            );
        },
        loginToken(account, data){
            localStorage.setItem('user', JSON.stringify(data));
            this.loginRequest(account);
            this.loginSuccess(data);
        },
        // 退出
        logout() {
            apiService.logout();
            this.status = {};
            this.user = null;
            this.userInfo = null;
        },
        // 注册账号
        registerUser(user) {
            const notificationStore = useNotificationStore();
            this.registerRequest(user)
            apiService.register(user).then((user) => {
                    this.registerSuccess(user)
                    notificationStore.success('注册成功');
                    router.push('/login');
                }, (error) => {
                    this.registerFailure()
                    notificationStore.error(error);
                }
            );
        },
        // 登录请求
        loginRequest(email) {
            this.user = email;
            this.status = { loggingIn: true };
        },
        // 登录成功
        loginSuccess(user) {
            let _this = this;
            _this.user = user;
            _this.status = { loggeduser: true };
            apiService.getAll(user.token).then((userInfo)=> {
                _this.userInfo = userInfo; // 1.用户信息
            });

            apiService.webInfo().then((webInfo) => {
                _this.websiteInfo = webInfo; // 2.网站信息
                router.push('/')
            });
        },
        loginFailure() {
            this.status = {};
            this.user = null;
            const notificationStore = useNotificationStore();
            notificationStore.success('登录失败');
        },
        // 注册请求
        registerRequest() {
            this.status = { registering: true }; // registering账号
        },
        // 注册成功
        registerSuccess() {
            this.status = {};
        },
        // 注册失败
        registerFailure() {
            this.status = {};
        }
    },
})