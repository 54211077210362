import { authHeader } from './auth-header';

export const apiService = {
    curl,
    login,
    logout,
    register,
    getAll,
    webInfo,
};

/**
 * 网络请求
 * @param url
 * @param params
 * @param method
 * @returns {Promise<*>}
 */
function curl(url, method = 'POST', params) {

    let isFileUpload = method;
    if( ['FILE', 'file'].includes(method) ) method = 'POST';
    let requestOptions = {method: method, headers: authHeader(params)};

    if( ['FILE', 'file'].includes(isFileUpload) ) {
        delete requestOptions['headers']['Content-Type'];
        requestOptions['body'] = params;
    } else {
        if( params !== '' && params !== undefined && params !== 'undefined' ) {
            if( ['GET', 'get'].includes(method) ) {
                url = url +'?'+ Object.keys(params).map(function (key) {return encodeURIComponent(key) + "=" + encodeURIComponent(params[key])}).join("&");
            }
            if( ['POST', 'post'].includes(method) ) requestOptions['body'] = JSON.stringify(params);
        }
    }

    return fetch(`/api/${url}`, requestOptions)
        .then(handleResponse)
        .then(res => {
            if( res !== undefined ) {
                // 100001 token过期, 100003 无效的账户
                if( [100001, 100002].includes(res.errCode) ) logout();
                return res;
            }
            return null;
        });

}

// 处理请求的返回值
function handleResponse(response) {
    return response.text().then(res => {
        const data = res && JSON.parse(res);
        if ( [401].includes(data.errCode) ) {
            if ( response.status === 401 ) {
                logout();
                location.reload();
            }
            return Promise.reject((data && data.errMsg) || response.statusText);
        }
        return data;
    });
}

// 账号登录
function login(email, password) {
    return curl('api/do-login', 'POST', { account: email, password: password }).then(response => {
        if ( response.errCode === 0 ) localStorage.setItem('user', JSON.stringify(response.data));
        return response;
    });
}

// 用户信息
function getAll(token) {
    return curl('user-info', 'GET', {token: token}).then(response => {
        if( response.errCode === 0 ) {
            localStorage.setItem('user-info', JSON.stringify(response.data));
            return response.data;
        }
        return null;
    });
}
// 用户信息
function webInfo() {
    return curl('api/website-info', 'GET').then(response => {
        if( response.errCode === 0 ) {
            localStorage.setItem('website-info', JSON.stringify(response.data));
            return response.data;
        }
        return null;
    });
}

// 从本地存储中删除用户以注销用户(账号退出)
function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('user-info');
    localStorage.removeItem('website-info');
}

// 账号注册
function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user)
    };
    return fetch(`/api/api/register`, requestOptions).then(handleResponse);
}



//函数判断
function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            return !!(typeof obj == 'object' && obj);
        } catch(e) {
            return false;
        }
    } else {
        return false;
    }
}
