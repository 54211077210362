import { defineStore } from "pinia";

export const useMyProStore = defineStore("myPro", {
    state: () => ({
        sp: null
    }),
    /**
     * 添加getters，接收一个对象，该对象里面是各种方法，
     * 你可以把它想象成Vue中的计算属性，它的作用就是返回一个结果，
     * getters也是会被缓存的，
     * 在getters中的，各个方法之间相互调用采用this关键字即可
     */
    getters: {
        // 定义一个getSp()方法，该方法默认接收一个state参数(即：上面的那个state)
        getSp(state) {
            if( state.sp != null ) return state.sp;
            let sp = localStorage.getItem('myPro');
            if( sp != null ) return JSON.parse(sp);
            return null;
        },
    },
    actions: {
        click(obj) {
            this.sp = obj;
            localStorage.setItem('myPro', JSON.stringify(obj));
        },
        clear() {
            this.sp = null;
        }
    }
});


